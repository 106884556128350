import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueCookie from 'vue-cookie';
import { instance } from '../config/index';

Vue.use(Vuex);
Vue.use(VueCookie);

function initialState() {
  return {
    user: {},
    userCnpj: '',
    //frete
    cepOrigem: '88911402',
    cepDestino: '',
    ibgeCod: null,
    // carrinho
    orderSize: {},
    cartLength: '',
    preOrderId: null,
    // filtros
    filterCategory: '',
    searchInput: '',
    headerShowSection: false,
    categorys: [
      {
        text: 'Categorias',
        isActive: false,
        subCategorys: [
          {
            name: 'Todas Categorias',
            value: '',
            isActive: false,
          },
          {
            name: 'Apostilas Área Administrativa e Atendimento',
            value: '000049',
            isActive: false,
          },
          {
            name: 'Apostilas Área Beleza',
            value: '000046',
            isActive: false,
          },
          {
            name: 'Apostilas Área Construção Civil',
            value: '000047',
            isActive: false,
          },
          {
            name: 'Apostilas Área de Idiomas',
            value: '000052',
            isActive: false,
          },
          {
            name: 'Apostilas Área Gastronomia',
            value: '000050',
            isActive: false,
          },
          {
            name: 'Apostilas Área Moda',
            value: '000070',
            isActive: false,
          },
          {
            name: 'Apostilas Área Saúde',
            value: '000043',
            isActive: false,
          },
          {
            name: 'Apostilas Área Saúde (Animais)',
            value: '000069',
            isActive: false,
          },
          {
            name: 'Apostilas Área Tecnologia',
            value: '000048',
            isActive: false,
          },
          {
            name: 'Apostilas Formato Híbrido',
            value: '000087',
            isActive: false,
          },
          {
            name: 'Apostilas Fotografia',
            value: '000079',
            isActive: false,
          },
          {
            name: 'Apostilas Gillis Interativos',
            value: '000084',
            isActive: false,
          },
          {
            name: 'Apostilas IM Digital',
            value: '000081',
            isActive: false,
          },
          {
            name: 'Apostilas IM Talking 2022',
            value: '000082',
            isActive: false,
          },
          {
            name: 'Apostilas Indústria E Mecânica',
            value: '000078',
            isActive: false,
          },
          {
            name: 'Apostilas Interativos IM',
            value: '000089',
            isActive: false,
          },
          {
            name: 'Apostilas Interativo IM (MSE)',
            value: '000071',
            isActive: false,
          },
          {
            name: 'Apostilas Método Individualizado Administração e Atendimento',
            value: '000053',
            isActive: false,
          },
          {
            name: 'Apostilas Método Individualizado Saúde',
            value: '000066',
            isActive: false,
          },
          {
            name: 'Apostilas Método Individualizado Tecnologia',
            value: '000051',
            isActive: false,
          },
          {
            name: 'Apostilas Turismo e Hotelaria',
            value: '000077',
            isActive: false,
          },
          {
            name: 'Brindes',
            value: '000025',
            isActive: false,
          },
          {
            name: 'Livro Intensivo',
            value: '000072',
            isActive: false,
          },
          {
            name: 'Material Administrativo para Franquia',
            value: '000054',
            isActive: false,
          },
          {
            name: 'Material de Divulgação por Família',
            value: '000055',
            isActive: false,
          },
          {
            name: 'Revistas',
            value: '000056',
            isActive: false,
          },
          {
            name: 'Uniformes para Alunos e Colaboradores',
            value: '00057',
            isActive: false,
          },
        ],
      },
      // {
      //   text: "IM Pay",
      //   isActive: false,
      //   subCategorys: [
      //     {
      //       name: "Maquininhas",
      //       value: "000073",
      //       isActive: false,
      //     }
      //   ]
      // },
    ],
    enter: '',
    // login
    login: '',
    password: '',
    token: Vue.cookie.get('token'),
    currentUserData: {
      fullName: Vue.cookie.get('user'),
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
  };
}

const getters = {
  getAuthenticatedStatus(state) {
    return !!state.token;
  },

  getPreOrderId(state) {
    return state.preOrderId;
  },

  getCurrentUserData(state) {
    return state.currentUserData;
  },

  getCategorys(state) {
    return state.categorys;
  },

  getHeaderShowSection(state) {
    return state.headerShowSection;
  },

  getCartSize() {
    return JSON.parse(localStorage.getItem('itemCart'));
  },

  getQtBox(state) {
    return state.orderSize?.qtBox;
  },

  getExternalCode(state) {
    return state?.user?.codigo_externo;
  },
};

const actions = {
  getUser() {
    return new Promise((resolve) => {
      const data = {
        codigo_usuario: Vue.cookie.get('codigoUser'),
      };

      instance()
        .post('/user/me', data)
        .then((response) => {
          store.commit('setUser', response);
          resolve('success');
        })
        .catch(() => {
          resolve('error');
        });
    });
  },

  postLogin() {
    return new Promise((resolve) => {
      instance()
        .post('/auth', {
          username: process.env.VUE_APP_IMNIC_API_USERNAME,
          password: process.env.VUE_APP_IMNIC_API_PASSWORD,
        })
        .then((response) => {
          Vue.cookie.set('token', response.access_token);
          store.dispatch('postLoginUser').then((response) => {
            if (response == 'error') {
              Vue.cookie.delete('token');
              resolve('error');
            } else {
              resolve('success');
            }
          });
        })
        .catch(() => {
          Vue.cookie.delete('token');
          resolve('error');
        });
    });
  },

  postLoginUser() {
    return new Promise((resolve) => {
      instance()
        .post('/user/login', {
          username: store.state.login,
          password: store.state.password,
        })
        .then((response) => {
          Vue.cookie.set('user', response.nome);
          Vue.cookie.set('codigoUser', response.codigo_usuario);
          store.commit('setToken', Vue.cookie.get('token'));
          if (response.codigo_usuario) {
            resolve('success');
          } else {
            resolve('error');
          }
        })
        .catch(() => {
          resolve('error');
        });
    });
  },

  logout({ commit }) {
    return new Promise((resolve) => {
      Vue.cookie.delete('token');
      Vue.cookie.delete('user');
      commit('setLogOut');
      resolve();
    });
  },

  cartLength(context, payload) {
    context.commit('cartLength', payload);
  },

  getOrderSize({ commit }, payload) {
    let cart = payload;
    let purchaseValue = 0;
    let qtItems = 0;
    let purchaseWeight = 0;
    let purchaseHeight = 0;
    let itemLength = 0;
    let itemWidth = 0;
    let cartSize = 0;

    return new Promise((resolve) => {
      cart.forEach((element) => {
        cartSize++, (purchaseValue += element.qt * parseFloat(element.valor));
        qtItems += element.qt;
        purchaseWeight += parseFloat(element.peso * element.qt);
        purchaseHeight += parseFloat(element.altura * element.qt);
        if (itemLength < parseFloat(element.comprimento)) {
          itemLength = parseFloat(element.comprimento);
        }
        if (itemWidth < parseFloat(element.largura)) {
          itemWidth = parseFloat(element.largura);
        }
      });

      let qtBox = Math.ceil((itemWidth * itemLength * purchaseHeight) / 23920); // new value 40540

      const orderSize = {
        cartSize: cartSize,
        qtItems: qtItems,
        purchaseValue: purchaseValue,
        purchaseWeight: purchaseWeight,
        purchaseHeight: purchaseHeight,
        itemLength: itemLength,
        itemWidth: itemWidth,
        cubicVolume: itemWidth * itemLength * purchaseHeight,
        qtBox: qtBox,
        metro3: 0.025 * qtBox, // new value 0.045
      };

      commit('setOrderSize', orderSize);
      resolve(orderSize);
    });
  },

  CalcJamef() {
    if (store.state.user.cep != null) {
      store.state.user.cep.replace('-', '');
    }

    return new Promise((resolve) => {
      const data = {
        weight: store.state.orderSize.purchaseWeight,
        valmer: store.state.orderSize.purchaseValue,
        metro: store.state.orderSize.metro3,
        zipcode_destiny: store.state.user.cep,
        user: 'jameff',
      };
      instance()
        .post('/ecommerce/jamef', data)
        .then((response) => {
          if (response == 'error' || response.previsao_entrega == null || response.valor == 0) {
            resolve('error');
          } else {
            let value = response.valor.replace(',', '');
            value = parseFloat(value) + 20;
            response.valor = value;
            resolve(response);
          }
        })
        .catch(() => {
          resolve('error');
        });
    });
  },

  CalcBrasPress() {
    return new Promise((resolve) => {
      const options = {
        method: 'POST',
        url: `${process.env.VUE_APP_BRASPRESS_API}/v1/cotacao/calcular/json`,
        params: { '': '' },
        timeout: 30000,
        headers: {
          'Content-Type': 'application/json',
          Authorization: process.env.VUE_APP_BRASPRESS_API_PASSWORD,
        },
        data: {
          cnpjRemetente: 35289544000108,
          cnpjDestinatario: store.state.userCnpj,
          modal: 'R',
          tipoFrete: 1,
          cepOrigem: store.state.cepOrigem,
          cepDestino: store.state.user.cep,
          vlrMercadoria: store.state.orderSize.purchaseValue,
          peso: store.state.orderSize.purchaseWeight,
          volumes: store.state.orderSize.qtBox,
          cubagem: [
            {
              altura: 0.24,
              comprimento: 0.3,
              largura: 0.36,
              volumes: store.state.orderSize.qtBox,
            },
          ],
        },
      };

      axios
        .request(options)
        .then(function (response) {
          if (response == 'error' || response.data.totalFrete == 0 || response.data.prazo == null) {
            resolve('error');
          } else {
            const value = parseFloat(response.data.totalFrete) + 20;
            response.data.totalFrete = value;
            resolve(response.data);
          }
        })
        .catch(() => {
          resolve('error');
        });
    });
  },

  CalcTNT() {
    if (store.state.user.cep != null) {
      store.state.user.cep.replace('-', '');
    }

    return new Promise((resolve) => {
      const data = {
        psReal: store.state.orderSize.purchaseWeight,
        vlMercadoria: store.state.orderSize.purchaseValue,
        cepDestino: store.state.user.cep,
        nrIdentifClienteDest: store.state.userCnpj,
        nrInscricaoEstadualDestinatario: store.state.user.insc_estadual,
      };

      instance()
        .post('/ecommerce/tnt', data)
        .then((response) => {
          if (response == 'error' || response.previsao_entrega == null || response.valor == 0) {
            resolve({ status: 'error', message: response.data.error });
          } else {
            let value = response.valor.replace(',', '');
            value = parseFloat(value) + 20;
            response.valor = value;
            resolve(response);
          }
        })
        .catch((error) => {
          resolve({ status: 'error', message: error?.response?.data || 'Erro desconhecido' });
        });
    });
  },

  CalcSaoMiguelAPI() {
    return new Promise((resolve) => {
      const options = {
        method: 'POST',
        url: 'https://srv.expressosaomiguel.com.br:40490/api-integracao-cliente/rest/frete/buscar/cliente',
        timeout: 30000,
        headers: {
          'Content-Type': 'application/json',
          access_key: process.env.VUE_APP_SAOMIGUEL_ACCESS_KEY,
          customer: process.env.VUE_APP_SAOMIGUEL_CUSTOMER,
        },
        data: {
          tipoPagoPagar: 'P',
          codigoCidadeDestino: store.state.ibgeCod,
          quantidadeMercadoria: store.state.orderSize.qtBox,
          pesoMercadoria: store.state.orderSize.purchaseWeight,
          valorMercadoria: store.state.orderSize.purchaseValue,
          tipoPeso: 'P',
          tipoPessoaDestino: 'J',
        },
      };
      axios
        .request(options)
        .then(function (response) {
          if (response.data.status == 'error' || response.data.valorFrete == 0 || response.data.previsaoEntrega == null) {
            resolve({ status: 'error', message: response.data.mensagem });
          } else {
            const value = parseFloat(response.data.valorFrete) + 20;

            response.data.valorFrete = value;

            resolve(response.data);
          }
        })
        .catch(() => {
          resolve({ status: 'error', message: 'Erro desconhecido' });
        });
    });
  },

  getIbgeCod({ commit }) {
    if (store.state.user.cep != null) {
      const cepFormated = store.state.user.cep.replace('-', '');

      return new Promise((resolve) => {
        const options = {
          method: 'GET',
          url: `https://viacep.com.br/ws/${cepFormated}/json/`,
        };

        axios
          .request(options)
          .then((res) => {
            if (res == 'erro') {
              resolve('error');
            } else {
              commit('setIbgeCod', res.data.ibge);
              resolve(res);
            }
          })
          .catch(() => {
            resolve('error');
          });
      });
    } else {
      return null;
    }
  },

  getPreOrder({ state }) {
    return new Promise((resolve) => {
      instance()
        .post('/ecommerce/getPreOrder', {
          order_id: state.preOrderId,
        })
        .then((res) => {
          resolve(res);
        })
        .catch(() => {
          resolve('error');
        });
    });
  },
};

const mutations = {
  setUserData(state, data) {
    state.login = data.login;
    state.password = data.password;
  },

  setIbgeCod(state, data) {
    state.ibgeCod = data;
  },

  setPreOrderId(state, data) {
    state.preOrderId = data;
  },

  setUser(state, data) {
    state.user = data;
    state.userCnpj = state.user.cnpj.replace('/', '');
  },

  setToken(state, data) {
    state.token = data;
  },

  setLogOut(state) {
    const inital = initialState();

    Object.keys(inital).forEach((key) => {
      state[key] = inital[key];
    });
  },

  filterCategory(state, payload) {
    state.filterCategory = payload;
  },

  enter(state, payload) {
    state.enter = payload;
  },

  searchInput(state, payload) {
    state.searchInput = payload;
  },

  cartLength(state, payload) {
    state.cartLength = payload;
  },

  setOrderSize(state, payload) {
    state.orderSize = payload;
  },
};

const store = new Vuex.Store({
  state: initialState(),
  actions,
  getters,
  mutations,
});

export default store;
