<template>
  <default-view-component :hasHeader="false" :hasSearchInput="true">
    <template v-slot:content>
      <div class="container">
        <div class="content">
          <h2>Carrinho</h2>
          <div v-if="items.length > 0" class="cart-items">
            <cart-item-component class="products" v-for="items in items" :key="items.id" :itemProps="items" @refreshCart="refreshCart()" @deleteItemCart="deleteItemCart($event)" />

            <div class="address">
              <h4>Endereço:</h4>
              <p v-if="address.nome_cidade">{{ address.nome_cidade }},</p>
              <p v-if="address.bairro">{{ address.bairro }},</p>
              <p v-if="address.cep">{{ zipcodeFormatter(address.cep) }}</p>
            </div>
            <div class="selection-container">
              <button-component
                @click.native="calcShipping(address.cep)"
                :isDisabled="hasRequest || selectedWithdrawn"
                :hasLoader="hasRequest"
                class="button-component"
                text="Calcular frete"
              />

              <div class="checkbox">
                <input v-model="selectedWithdrawn" type="checkbox" id="Retirada" @click="selectWithdrawn(selectedWithdrawn)" />
                <label for="Retirada">Retirar na Editora</label>
              </div>
            </div>
            <div class="shipping animate__animated animate__fadeIn animate__slow" v-if="(JamefStatus.error && BraspressStatus.error) || (shipping && !selectedWithdrawn)">
              <h2>Frete</h2>

              <!-- <div class="select" v-if="JamefStatus.error">
                <p>Jamef indisponível</p>
              </div>
              <div v-else>
                <div class="select" v-if="jamef">
                  <input type="radio" id="Jamef" :value="jamef.valor" v-model="valueSelectedShipping" @click="selectShipping('JAMEF')" />

                  <label for="Jamef">Jamef: {{ priceFormatter(jamef.valor) }} </label>

                  <p>Previsão de entrega: {{ diffDate(jamef.previsao_entrega) + 5 }} dias</p>
                </div>

                <div v-else class="shimmer">
                  <p>Calculando Jamef...</p>
                </div>
              </div> -->

              <div class="select" v-if="BraspressStatus.error">
                <p>Braspress indisponível</p>
                <p class="error-message">{{ BraspressStatus.message }}</p>
              </div>

              <div v-else>
                <div class="select" v-if="braspress">
                  <input type="radio" id="BrasPress" :value="braspress.totalFrete" v-model="valueSelectedShipping" @click="selectShipping('BRASPRESS')" />

                  <label for="BrasPress">BrasPress: {{ priceFormatter(braspress.totalFrete) }} </label>

                  <p>Previsão de entrega: {{ parseInt(braspress.prazo) + 5 }} dias</p>
                </div>

                <div v-else class="shimmer">
                  <p>Calculando BrasPress...</p>
                </div>
              </div>

              <div class="select" v-if="SaoMiguelStatus.error">
                <p>Expresso São Miguel indisponível</p>
                <p class="error-message">{{ SaoMiguelStatus.message }}</p>
              </div>

              <div v-else>
                <div class="select" v-if="saoMiguel" @click="selectShipping('SAOMIGUEL')">
                  <input type="radio" id="saoMiguel" :value="saoMiguel.valorFrete" v-model="valueSelectedShipping" />

                  <label for="saoMiguel">Expresso São Miguel: {{ priceFormatter(saoMiguel.valorFrete) }} </label>

                  <p>Previsão de entrega: {{ diffDate(saoMiguel.previsaoEntrega) + 5 }} dias</p>
                </div>

                <div v-else class="shimmer">
                  <p>Calculando Expresso São Miguel...</p>
                </div>
              </div>

              <div class="select" style="border-color: red;" v-if="!$store.state.user.insc_estadual">
                <p>Transportadora TNT</p>
                <p>Necessário atualização de dados, entre em contato com o suporte!</p>
              </div>

              <div class="select" v-else-if="TNTStatus.error">
                <p>TNT indisponível</p>
                <p class="error-message">{{ TNTStatus.message }}</p>
              </div>

              <div v-else>
                <div class="select" v-if="tnt">
                  <input type="radio" id="TNT" :value="tnt.valor" v-model="valueSelectedShipping" @click="selectShipping('TNT')" />

                  <label for="TNT">TNT: {{ priceFormatter(tnt.valor) }} </label>

                  <p>Previsão de entrega: {{ diffDate(tnt.previsao_entrega) + 5 }} dias</p>
                </div>

                <div v-else class="shimmer">
                  <p>Calculando TNT...</p>
                </div>
              </div>

              <div class="select" v-if="JamefStatus.error && BraspressStatus.error && SaoMiguelStatus.error && TNTStatus.error">
                <input type="checkbox" id="A combinar" v-model="selectedToMatch" @click="selectToMatch(selectedToMatch)" />
                <label for="A combinar"> A combinar </label>
              </div>
            </div>
          </div>
          <div v-else class="empty-cart">
            <h1>
              Seu carrinho está vazio
              <i class="icon-remove_shopping_cart"></i>
            </h1>
            <button-component @click.native="toMarketplace" class="button-component" text="Continuar comprando" styles="ghost" />
          </div>
        </div>

        <div v-if="items.length" class="summary-container">
          <h2>Resumo</h2>

          <div class="summary">
            <div class="item">
              <p class="text">Valor dos produtos:</p>
              <p class="amount">{{ priceFormatter(purchaseValue) }}</p>
            </div>

            <div class="item" v-if="valueSelectedShipping != 0">
              <p class="text">Frete:</p>
              <p class="amount">{{ priceFormatter(valueSelectedShipping) }}</p>
            </div>

            <div class="item">
              <p class="text">Valor total:</p>
              <p class="amount">
                {{ priceFormatter(purchaseValue + parseFloat(valueSelectedShipping)) }}
              </p>
            </div>
          </div>

          <div class="buttons-container">
            <button-component @click.native="toMarketplace" class="button-component" text="Continuar comprando" styles="ghost" />
            <button-component
              @click.native="openModal"
              :isDisabled="!selectedWithdrawn && !selectedToMatch && valueSelectedShipping == 0"
              class="button-component"
              text="Finalizar pedido"
            />
          </div>
        </div>
        <div v-if="open" class="showModal">
          <purchase-modal
            @close="closeModal"
            :showModal="true"
            :purchaseDetail="{
              items,
              selectedWithdrawn,
              valor_frete: valueSelectedShipping,
              company,
              purchaseValue,
            }"
            :AmountProps="priceFormatter(purchaseValue + parseFloat(valueSelectedShipping))"
            title="Detalhes do pedido"
          />
        </div>
      </div>
    </template>
  </default-view-component>
</template>

<script>
import DefaultView from '@/components/Views/DefaultView';
import Button from '@/components/Button';
import PurchaseModalVue from '../components/PurchaseModal.vue';
import CartItemCard from '../components/CartItemCard.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Cart',

  components: {
    'default-view-component': DefaultView,
    'button-component': Button,
    'purchase-modal': PurchaseModalVue,
    'cart-item-component': CartItemCard,
  },

  data() {
    return {
      selectedWithdrawn: false,
      selectedToMatch: false,
      open: false,
      purchaseValue: 0,
      items: {},
      hasRequest: false,
      address: {},
      jamef: '',
      braspress: '',
      saoMiguel: '',
      tnt: '',
      JamefStatus: {
        error: false,
        loading: false,
        message: '',
      },
      SaoMiguelStatus: {
        error: false,
        loading: false,
        message: '',
      },
      TNTStatus: {
        error: false,
        loading: false,
        message: '',
      },
      BraspressStatus: {
        error: false,
        loading: false,
        message: '',
      },
      company: 'A combinar',
      shipping: false,
      valueSelectedShipping: 0,
    };
  },

  computed: {
    ...mapGetters(['getExternalCode', 'getAuthenticatedStatus', 'getPreOrderId']),
  },

  created() {
    const preOrderId = this.$route.query.preOrderId;
    if (preOrderId) {
      localStorage.setItem('preOrderId', preOrderId);
      this.$store.commit('setPreOrderId', preOrderId);
    }

    this.fetchData();
  },


  methods: {
    async fetchData() {
      await this.getUser();

      if (this.getAuthenticatedStatus && this.getPreOrderId) {
        await this.$store.dispatch('getPreOrder', this.getPreOrderId).then((res) => {
          localStorage.setItem('itemCart', JSON.stringify(res));
        });

        this.$store.commit('setPreOrderId', null);
        localStorage.removeItem('preOrderId');
      }

      this.getCart();
    },

    diffDate(date) {
      const day = this.$dayjs();

      const diffDate = this.$dayjs(date, 'DD/MM/YYYY');

      return diffDate.diff(day, 'day');
    },

    async getUser() {
      await this.$store.dispatch('getUser').then(() => {
        this.address = this.$store.state.user;
      });
    },

    openModal() {
      this.open = true;
    },

    closeModal() {
      this.open = false;
    },

    selectShipping(value) {
      this.company = value;
    },

    selectToMatch() {
      this.company = 'A combinar';
      this.selectedWithdrawn = false;
      this.valueSelectedShipping = 0;
    },

    selectWithdrawn() {
      this.valueSelectedShipping = 0;
      this.company = 'RETIRAR EM MAOS';
      this.selectedToMatch = false;
    },

    zipcodeFormatter(value) {
      return this.$service.zipcodeFormatter(value);
    },

    calcShipping() {
      this.JamefStatus.error = false;
      this.BraspressStatus.error = false;
      this.SaoMiguelStatus.error = false;
      this.TNTStatus.error = false;
      this.hasRequest = true;
      this.shipping = false;
      this.jamef = '';
      this.braspress = '';
      this.tnt = '';
      this.saoMiguel = '';
      this.valueSelectedShipping = 0;
      
      // Contador para controlar quando todas as requisições terminaram
      let completedRequests = 0;
      const totalRequests = 2; // TNT e Braspress (São Miguel é chamado após getIbgeCod)
      
      const checkAllRequestsComplete = () => {
        completedRequests++;
        if (completedRequests >= totalRequests) {
          this.hasRequest = false;
        }
      };

      this.$store.dispatch('getUser').then(() => {
        // this.$store.dispatch("CalcJamef").then((response) => {
        //   if (response == "error") {
        //     this.JamefStatus.error = true;
        //   } else {
        //     this.jamef = response;
        //     this.JamefStatus.error = false;
        //     this.shipping = true;
        //     this.hasRequest = false;
        //   }
        // });

        this.$store.dispatch('CalcBrasPress').then((response) => {
          if (response == 'error') {
            this.BraspressStatus.error = true;
            this.BraspressStatus.message = 'Erro ao calcular frete';
            this.shipping = true;
          } else {
            this.braspress = response;
            this.BraspressStatus.error = false;
            this.shipping = true;
          }
          checkAllRequestsComplete();
        })
        .catch((error) => {
          this.BraspressStatus.error = true;
          this.BraspressStatus.message = error?.message || 'Erro ao calcular frete';
          this.shipping = true;
          checkAllRequestsComplete();
        });

        this.$store
          .dispatch('CalcTNT')
          .then((response) => {
            if (response.message.error) {
              this.TNTStatus.error = true;
              this.TNTStatus.message = response?.message?.error || 'Erro ao calcular frete';
            } else {
              this.tnt = response;
              this.TNTStatus.error = false;
              this.shipping = true;
            }
            checkAllRequestsComplete();
          })
          .catch((error) => {
            this.TNTStatus.error = true;
            this.TNTStatus.message = error?.message || 'Erro ao calcular frete';
            checkAllRequestsComplete();
          });

        this.$store.dispatch('getIbgeCod').then(() => {
          this.$store.dispatch('CalcSaoMiguelAPI').then((response) => {
            if (response.status == 'error') {
              this.SaoMiguelStatus.error = true;
              this.SaoMiguelStatus.message = response?.message || 'Erro ao calcular frete';
            } else {
              this.saoMiguel = response;
              this.SaoMiguelStatus.error = false;
              this.shipping = true;
            }
          })
          .catch((error) => {
            this.SaoMiguelStatus.error = true;
            this.SaoMiguelStatus.message = error?.message || 'Erro ao calcular frete';
          });
        });
      });
    },

    toMarketplace() {
      this.$router.push('/');
    },

    refreshCart() {
      localStorage.setItem('itemCart', JSON.stringify(this.items));
      this.getCart();
      this.shipping = false;
      this.jamef = '';
      this.braspress = '';
      this.valueSelectedShipping = 0;
      this.JamefStatus.error = false;
      this.BraspressStatus.error = false;
    },

    priceFormatter(value) {
      return this.$service.priceFormatter(value);
    },

    getOrderSize() {
      this.$store.dispatch('getOrderSize', this.items).then((response) => {
        this.purchaseValue = response.purchaseValue;
      });
    },

    deleteItemCart(itemIndex) {
      const itemDelete = this.items.filter((i) => i.codigo !== itemIndex);
      localStorage.setItem('itemCart', JSON.stringify(itemDelete));
      this.getCart();
      this.refreshCart();
    },

    getCart() {
      const cart = JSON.parse(localStorage.getItem('itemCart'));
      if (cart != null) {
        this.items = cart;
        this.getOrderSize();
      } else {
        this.items = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #fff;
  border-radius: 0.3rem;
  box-shadow: rgb(40 41 61 / 2%) 0 0.25rem 0.5rem, rgb(96 97 112 / 10%) 0 0.5rem 1rem;
  display: flex;
  justify-content: center;
  margin: 2rem 0 4rem 0;
  padding: 0 1rem 1rem;
  width: 75rem;

  .content {
    height: fit-content;
    margin-right: 2rem;
    padding: 1rem;
    width: 100%;

    .error-message {
      color: #aa1428;
      font-size: small;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .button-component {
      height: 4rem;
      width: auto;
    }

    .cart-items {
      margin: 1rem 0;

      .address {
        display: flex;
        h4,
        p {
          padding: 0.2rem;
        }
      }

      .selection-container {
        align-items: center;
        display: flex;
        margin-top: 0.5rem;

        .button-component {
          height: 4rem;
          width: auto;
        }

        .checkbox {
          border: 0.01rem solid hsl(0, 0%, 56%, 0.6);
          background-color: #f5f5f5;
          border-radius: 0.3rem;
          cursor: pointer;
          margin-left: 1rem;
          max-width: fit-content;
          padding: 0.7rem 1rem;

          &:hover {
            background-color: rgb(255, 139, 0, 0.6);
          }

          label {
            font-size: 0.875rem;
            cursor: pointer;
            font-weight: 400;
            margin-left: 0.4rem;
          }
        }
      }

      .shipping {
        border-radius: 0.3rem;
        display: flex;
        flex-direction: column;
        max-width: fit-content;
        padding: 1rem 0;

        .select {
          background-color: #f5f5f5;
          border: 0.01rem solid hsl(0, 0%, 56%, 0.6);
          border-radius: 0.3rem;
          margin: 0.4rem 0;
          cursor: pointer;
          padding: 0.7rem;

          &:hover {
            background: #b3b3b3;
            border-radius: 0.3rem;
          }

          input {
            cursor: pointer;
          }

          label {
            cursor: pointer;
            padding-left: 0.5rem;
          }

          p {
            margin: 0.1rem;
          }
        }
      }

      .shimmer {
        animation: shimmer 3s ease infinite;
        background: linear-gradient(-60deg, #a3a3a3, #c7c7c7, #a1a1a1, #c7c7c7);
        background-size: 400% 400%;
        border-radius: 0.3rem;
        width: 100%;

        p {
          margin: 0.1rem;
          padding: 0.6rem;
        }
      }
    }

    .empty-cart {
      h1 {
        color: #aa1428;
        margin: 1rem 0;
      }
      margin: 1rem 0;
      justify-content: center;
    }
  }

  .summary-container {
    background: #f5f5f5;
    border-radius: 0.3rem;
    height: fit-content;
    margin: 1rem 0 0;
    min-width: 21rem;
    padding: 1rem;
    position: sticky;
    top: 2rem;

    .summary {
      margin-top: 0.5rem;

      .item {
        align-items: center;
        display: flex;
        margin-bottom: 0.5rem;

        p {
          &.amount {
            font-weight: 500;
            margin-left: 0.5rem;
          }
        }
      }
    }
    .buttons-container {
      display: flex;
      justify-content: space-between;
      margin-top: 1.5rem;

      .button-component {
        max-width: 9rem;
        height: 4rem;
        margin-top: 0.75rem;
      }
    }
  }
}
@keyframes shimmer {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@media (max-width: 835px) {
  .container {
    flex-direction: column;
    max-width: 100%;

    .content {
      margin-right: 0;
      padding: 1rem 0 0;
      h1 {
        text-align: center;
      }

      .button-component {
        width: 100%;
      }

      .cart-items {
        .shipping {
          max-width: 100%;
        }

        .address {
          flex-wrap: wrap;
        }

        .selection-container {
          flex-wrap: wrap;
          justify-content: center;
          .button-component {
            width: 100%;
          }
          .checkbox {
            margin-left: 0;
            margin-top: 1rem;
            min-width: 100%;
            text-align: center;
          }
        }
      }
    }

    .summary-container {
      margin: 0;
      min-width: 19.5rem;
    }
  }
}
</style>
